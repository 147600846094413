/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";


.p-menuitem-link-active {
    background-color: var(--blue-200)
}

.p-datatable .p-datatable-wrapper {
    overflow: unset !important;
}
